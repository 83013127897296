import { useState, useCallback, useEffect, useMemo } from 'react';
import { isUndefined } from '@iheartradio/web.utilities';

const useImage = ({
  onError,
  onLoad,
  src
}) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [status, setStatus] = useState('pending');

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onerror = useCallback(event => {
    setStatus('failed');
    onError?.(event.error);
  }, [onError]);
  const onload = useCallback(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  event => {
    setHeight(event.currentTarget.height);
    setWidth(event.currentTarget.width);
    setStatus('suceeded');
    onLoad?.(event);
  }, [onLoad]);
  const load = useCallback(() => {
    const image = new Image();
    image.addEventListener('load', onload);
    image.addEventListener('error', onerror);
    image.src = src ?? '';
  }, [onerror, onload, src]);
  useEffect(() => setStatus('pending'), [src]);
  useEffect(() => load(), [load, src]);
  return useMemo(() => ({
    height,
    source: src,
    status,
    width
  }), [height, src, status, width]);
};

function usePrefersReducedMotion() {
  const media = useMemo(() => {
    if (isUndefined(globalThis?.window)) return {
      matches: false
    };
    return window.matchMedia('(prefers-reduced-motion: reduce)');
  }, []);
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(media.matches);
  useEffect(() => {
    function listen(e) {
      setPrefersReducedMotion(e.matches);
    }
    media.addEventListener('change', listen);
    return () => media.removeEventListener('change', listen);
  }, [media]);
  return prefersReducedMotion;
}

export { usePrefersReducedMotion as a, useImage as u };
